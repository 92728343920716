import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadingUser,
  noUser,
  loadUser,
  logoutUser,
} from "./store/redux/userRedux";
import axios from "axios";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Transactions from "./pages/Transactions";
import Users from "./pages/Users";
import UserDetails from "./pages/UserDetails";
import TransactionDetails from "./pages/TransactionDetails";

const App = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("paylio-admin");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://paylio-backend.onrender.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        dispatch(loadUser(data));
      } catch (error: any) {
        if (error.response.data === "Token is not valid!") {
          localStorage.removeItem("paylio-admin");
          dispatch(logoutUser());
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route
        path="/"
        element={user && user.isAdmin ? <Dashboard /> : <Login />}
      />
      <Route
        path="/dashboard"
        element={
          user && user.isAdmin ? <Dashboard /> : <Navigate replace to={"/"} />
        }
      />
      <Route
        path="/transaction/:transactionId"
        element={
          user && user.isAdmin ? (
            <TransactionDetails />
          ) : (
            <Navigate replace to={"/"} />
          )
        }
      />
      <Route
        path="/transactions"
        element={
          user && user.isAdmin ? (
            <Transactions />
          ) : (
            <Navigate replace to={"/"} />
          )
        }
      />
      <Route
        path="/users"
        element={
          user && user.isAdmin ? <Users /> : <Navigate replace to={"/"} />
        }
      />
      <Route
        path="/user/:id"
        element={
          user && user.isAdmin ? <UserDetails /> : <Navigate replace to={"/"} />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
