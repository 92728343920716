import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import Preloader from "../components/Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [suspendModalOpen, setSuspendModalOpen] = useState(false);
  const [unsuspendModalOpen, setUnsuspendModalOpen] = useState(false);
  const [idToSuspend, setIdtoSuspend] = useState("");
  const [idToUnsuspend, setIdtoUnsuspend] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [toolsActive, setToolsActive] = useState(false);
  const [displayedUsers, setDisplayedUsers] = useState(10);
  const [usersToDisplay, setUsersToDisplay] = useState(displayedUsers);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchingForUser, setSearchingForUser] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [idToVerify, setIdToVerify] = useState("");
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);

  const navigate = useNavigate();

  const loadMoreUsers = () => {
    setUsersToDisplay(usersToDisplay + displayedUsers);
  };

  const toggleSearchActive = () => {
    setSearchActive(!searchActive);
  };

  const toggleToolsActive = () => {
    setToolsActive(!toolsActive);
  };

  const suspendUser = async () => {
    const token = localStorage.getItem("paylio-admin");
    setLoading(true);

    try {
      await axios.put(
        "https://paylio-backend.onrender.com/api/user/suspend",
        { idToSuspend },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setSuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const unsuspendUser = async () => {
    const token = localStorage.getItem("paylio-admin");
    setLoading(true);

    try {
      await axios.put(
        "https://paylio-backend.onrender.com/api/user/unsuspend",
        { idToUnsuspend },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setUnsuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const verifyEmail = async () => {
    const token = localStorage.getItem("paylio-admin");
    setLoading(true);

    try {
      await axios.put(
        `https://paylio-backend.onrender.com/api/user/verify/${idToVerify}`,
        { verified: true },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("paylio-admin");

    const getUsers = async () => {
      const { data } = await axios.get(
        "https://paylio-backend.onrender.com/api/user/find",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setUsers(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUsers();
  }, []);

  useEffect(() => {
    if (searchingForUser) {
      const foundUser = users?.filter((user: any) => {
        return Object.values(user.firstName)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(foundUser);

      if (foundUser.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  }, [searchingForUser, searchTerm, users]);

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Users Lists
                              </h3>
                              <div className="nk-block-des text-soft">
                                <p>You have total {users?.length} users</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="card card-stretch">
                            <div className="card-inner-group">
                              <div className="card-inner position-relative card-tools-toggle">
                                <div className="card-title-group">
                                  <div className="card-tools"></div>
                                  <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={toggleSearchActive}
                                          className="btn btn-icon search-toggle toggle-search"
                                          data-target="search"
                                        >
                                          <em className="icon ni ni-search" />
                                        </a>
                                      </li>
                                      <li className="btn-toolbar-sep" />
                                      <li>
                                        <div className="toggle-wrap">
                                          <a
                                            href="#/"
                                            onClick={toggleToolsActive}
                                            className="btn btn-icon btn-trigger toggle"
                                            data-target="cardTools"
                                          >
                                            <em className="icon ni ni-menu-right" />
                                          </a>
                                          <div
                                            className={`toggle-content ${
                                              toolsActive
                                                ? "content-active"
                                                : ""
                                            }`}
                                            data-content="cardTools"
                                          >
                                            <ul className="btn-toolbar gx-1">
                                              <li className="toggle-close">
                                                <a
                                                  href="#/"
                                                  onClick={toggleToolsActive}
                                                  className="btn btn-icon btn-trigger toggle"
                                                  data-target="cardTools"
                                                >
                                                  <em className="icon ni ni-arrow-left" />
                                                </a>
                                              </li>

                                              <li>
                                                <div className="dropdown">
                                                  <a
                                                    href="#/"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-setting" />
                                                  </a>
                                                  <div className="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                                    <ul className="link-check">
                                                      <li>
                                                        <span>Show</span>
                                                      </li>
                                                      <li
                                                        onClick={() =>
                                                          setDisplayedUsers(10)
                                                        }
                                                        className={
                                                          displayedUsers === 10
                                                            ? "active"
                                                            : ""
                                                        }
                                                      >
                                                        <a href="#/">10</a>
                                                      </li>
                                                      <li
                                                        onClick={() =>
                                                          setDisplayedUsers(20)
                                                        }
                                                        className={
                                                          displayedUsers === 20
                                                            ? "active"
                                                            : ""
                                                        }
                                                      >
                                                        <a href="#/">20</a>
                                                      </li>
                                                      <li
                                                        onClick={() =>
                                                          setDisplayedUsers(50)
                                                        }
                                                        className={
                                                          displayedUsers === 50
                                                            ? "active"
                                                            : ""
                                                        }
                                                      >
                                                        <a href="#/">50</a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className={`card-search search-wrap ${
                                    searchActive ? "active" : ""
                                  }`}
                                  data-search="search"
                                >
                                  <div className="card-body">
                                    <div className="search-content">
                                      <a
                                        href="#/"
                                        onClick={toggleSearchActive}
                                        className="search-back btn btn-icon toggle-search"
                                        data-target="search"
                                      >
                                        <em className="icon ni ni-arrow-left" />
                                      </a>
                                      <input
                                        type="text"
                                        className="form-control border-transparent form-focus-none"
                                        placeholder="Search by user first name"
                                        onChange={(e) => {
                                          setSearchingForUser(true);
                                          setSearchTerm(e.target.value);
                                        }}
                                      />
                                      <button className="search-submit btn btn-icon">
                                        <em className="icon ni ni-search" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-inner p-0">
                                <div className="nk-tb-list nk-tb-ulist is-compact">
                                  <div className="nk-tb-item nk-tb-head">
                                    <div className="nk-tb-col">
                                      <span className="sub-text">Name</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                      <span className="sub-text">Email</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-sm">
                                      <span className="sub-text">Phone</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                      <span className="sub-text">Balance</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-lg">
                                      <span className="sub-text">
                                        Joined on
                                      </span>
                                    </div>
                                    <div className="nk-tb-col">
                                      <span className="sub-text">Status</span>
                                    </div>
                                    <div className="nk-tb-col nk-tb-col-tools text-end"></div>
                                  </div>
                                  {!searchTerm && users?.length > 0 && (
                                    <>
                                      {users
                                        ?.slice(0, usersToDisplay)
                                        ?.map((user: any) => (
                                          <div
                                            className="nk-tb-item"
                                            key={user._id}
                                          >
                                            <div className="nk-tb-col">
                                              <div className="user-card">
                                                <div className="user-name">
                                                  <span className="tb-lead">
                                                    {user.firstName}{" "}
                                                    {user.middleName}{" "}
                                                    {user.lastName}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="nk-tb-col tb-col-md">
                                              <span>{user.email}</span>
                                            </div>
                                            <div className="nk-tb-col tb-col-sm">
                                              <span>{user.phone}</span>
                                            </div>
                                            <div className="nk-tb-col tb-col-md">
                                              <span>
                                                $
                                                {user.balance?.toLocaleString()}
                                              </span>
                                            </div>
                                            <div className="nk-tb-col tb-col-lg">
                                              <span>
                                                {new Date(
                                                  user.createdAt
                                                ).toDateString()}
                                              </span>
                                            </div>
                                            <div className="nk-tb-col">
                                              {user.disabled ? (
                                                <span className="tb-status text-danger">
                                                  Suspended
                                                </span>
                                              ) : (
                                                <span className="tb-status text-success">
                                                  Active
                                                </span>
                                              )}
                                            </div>
                                            <div className="nk-tb-col nk-tb-col-tools">
                                              <ul className="nk-tb-actions gx-2">
                                                <li>
                                                  <div className="drodown">
                                                    <a
                                                      href="#/"
                                                      className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
                                                      data-bs-toggle="dropdown"
                                                    >
                                                      <em className="icon ni ni-more-h" />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                      <ul className="link-list-opt no-bdr">
                                                        <li>
                                                          <Link
                                                            to={`/user/${user._id}`}
                                                          >
                                                            <em className="icon ni ni-eye" />
                                                            <span>
                                                              View Details
                                                            </span>
                                                          </Link>
                                                        </li>
                                                        <li className="divider" />
                                                        {!user.verified && (
                                                          <>
                                                            <li>
                                                              <a
                                                                href="#/"
                                                                onClick={() => {
                                                                  setIdToVerify(
                                                                    user._id
                                                                  );
                                                                  setVerifyModalOpen(
                                                                    true
                                                                  );
                                                                }}
                                                              >
                                                                <em className="icon ni ni-shield-star" />
                                                                <span>
                                                                  Verify Email
                                                                </span>
                                                              </a>
                                                            </li>
                                                            <li className="divider" />
                                                          </>
                                                        )}
                                                        {!user.disabled ? (
                                                          <li>
                                                            <a
                                                              href="#/"
                                                              onClick={() => {
                                                                setSuspendModalOpen(
                                                                  true
                                                                );
                                                                setIdtoSuspend(
                                                                  user._id
                                                                );
                                                              }}
                                                            >
                                                              <em className="icon ni ni-na" />
                                                              <span>
                                                                Suspend User
                                                              </span>
                                                            </a>
                                                          </li>
                                                        ) : (
                                                          <li>
                                                            <a
                                                              href="#/"
                                                              onClick={() => {
                                                                setUnsuspendModalOpen(
                                                                  true
                                                                );
                                                                setIdtoUnsuspend(
                                                                  user._id
                                                                );
                                                              }}
                                                            >
                                                              <em className="icon ni ni-na" />
                                                              <span>
                                                                Unsuspend User
                                                              </span>
                                                            </a>
                                                          </li>
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  )}
                                  {searchTerm && searchResults?.length > 0 && (
                                    <>
                                      {searchResults?.map((user: any) => (
                                        <div
                                          className="nk-tb-item"
                                          key={user._id}
                                        >
                                          <div className="nk-tb-col">
                                            <div className="user-card">
                                              <div className="user-name">
                                                <span className="tb-lead">
                                                  {user.firstName}{" "}
                                                  {user.middleName}{" "}
                                                  {user.lastName}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="nk-tb-col tb-col-md">
                                            <span>{user.email}</span>
                                          </div>
                                          <div className="nk-tb-col tb-col-sm">
                                            <span>{user.phone}</span>
                                          </div>
                                          <div className="nk-tb-col tb-col-md">
                                            ${user.balance?.toLocaleString()}
                                          </div>
                                          <div className="nk-tb-col tb-col-lg">
                                            <span>
                                              {new Date(
                                                user.createdAt
                                              ).toDateString()}
                                            </span>
                                          </div>
                                          <div className="nk-tb-col">
                                            {user.disabled ? (
                                              <span className="tb-status text-danger">
                                                Suspended
                                              </span>
                                            ) : (
                                              <span className="tb-status text-success">
                                                Active
                                              </span>
                                            )}
                                          </div>
                                          <div className="nk-tb-col nk-tb-col-tools">
                                            <ul className="nk-tb-actions gx-2">
                                              <li>
                                                <div className="drodown">
                                                  <a
                                                    href="#/"
                                                    className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-more-h" />
                                                  </a>
                                                  <div className="dropdown-menu dropdown-menu-end">
                                                    <ul className="link-list-opt no-bdr">
                                                      <li>
                                                        <Link
                                                          to={`/user/${user._id}`}
                                                        >
                                                          <em className="icon ni ni-eye" />
                                                          <span>
                                                            View Details
                                                          </span>
                                                        </Link>
                                                      </li>
                                                      <li className="divider" />
                                                      {!user.verified && (
                                                        <>
                                                          <li>
                                                            <a
                                                              href="#/"
                                                              onClick={() => {
                                                                setIdToVerify(
                                                                  user._id
                                                                );
                                                                setVerifyModalOpen(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              <em className="icon ni ni-shield-star" />
                                                              <span>
                                                                Verify Email
                                                              </span>
                                                            </a>
                                                          </li>
                                                          <li className="divider" />
                                                        </>
                                                      )}
                                                      {!user.disabled ? (
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setSuspendModalOpen(
                                                                true
                                                              );
                                                              setIdtoSuspend(
                                                                user._id
                                                              );
                                                            }}
                                                          >
                                                            <em className="icon ni ni-na" />
                                                            <span>
                                                              Suspend User
                                                            </span>
                                                          </a>
                                                        </li>
                                                      ) : (
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setUnsuspendModalOpen(
                                                                true
                                                              );
                                                              setIdtoUnsuspend(
                                                                user._id
                                                              );
                                                            }}
                                                          >
                                                            <em className="icon ni ni-na" />
                                                            <span>
                                                              Unsuspend User
                                                            </span>
                                                          </a>
                                                        </li>
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                  {(noResults || users?.length === 0) && (
                                    <p
                                      style={{
                                        position: "relative",
                                        left: "50%",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      No records
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {usersToDisplay < users?.length && (
                        <div className="text-center pt-4">
                          <a
                            href="#/"
                            className="link link-soft"
                            onClick={loadMoreUsers}
                          >
                            <em className="icon ni ni-redo" />
                            <span>Load More</span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}

      {suspendModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setSuspendModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Suspend Account</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to suspend this account?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Suspending...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={suspendUser}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setSuspendModalOpen(false)}
          />
        </>
      )}

      {unsuspendModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setUnsuspendModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Unsuspend User</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to unsuspend this user?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Unsuspending...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={unsuspendUser}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setUnsuspendModalOpen(false)}
          />
        </>
      )}

      {verifyModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setVerifyModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Verify Email</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to verify this user?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Verifying...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={verifyEmail}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setVerifyModalOpen(false)}
          />
        </>
      )}

      <ToastContainer
        position="bottom-center"
        theme="dark"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Users;
